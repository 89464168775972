import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/Pkw"
import { FormPKW } from "../components/FormPKW"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { Partners } from "../components/PartnersNew"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"
import { MoreBenefitsInvitefriend } from "../components/MoreBenefitsInvitefriend"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"
import { QUESTIONS_FOR_ROSTICS } from "../components/Questions/helpers"
import { partnersRostics } from "../components/PartnersNew/helpers"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerDescription = `
  За оформление карты «Халва»
  <br />
  и покупку от 700 ₽ в ROSTIC'S
`

const subTitlePartners =
  "Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца срок рассрочки на новые покупки устанавливает партнер:"

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const isMobile = useMediaQuery("(max-width:380px)")

  const bannerTitle = isMobile
    ? `<span>Кэшбэк 700 баллов</span>
  <br/>
  и 7 промокодов<br/>
  на кофе за 1 ₽
  `
    : `
  <span>Кэшбэк 700 баллов</span>
  <br/>
  и 7 промокодов<br/>
  на кофе за 1 ₽
`

  const benefitsTitle = (
    <>
      Как получить 700 баллов кэшбэка <br /> и&nbsp;7 промокодов на кофе за 1 ₽
    </>
  )

  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank showHint={false} hasCTA additionalEventInDataLayer />
      <Banner
        variant="rostics"
        title={bannerTitle}
        description={bannerDescription}
        bgVariant="rosticsBg"
        orderNum="1"
      />
      <MoreBenefitsInvitefriend variant="rostics" orderNum="2" title={benefitsTitle} />
      <FormPKW
        progressBar
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
      />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="4"
      />
      <Partners
        additionalPartners={partnersRostics}
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="5"
      />
      <Questions questionList={QUESTIONS_FOR_ROSTICS} additionalEventInDataLayer orderNum="6" />
      <NewFooter ligal={ligal} orderNum="7" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/rostics/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
